import React, { FC } from "react";
import Layout from "../components/layout";

const ImpressumPage: FC = () => (
  <Layout pageTitle={"home"}>
    <p>Impressum</p>
  </Layout>
);

export default ImpressumPage;
